const aws_exports = {
    "Auth": {
        "Cognito": {
            "region": "us-east-1",
            "userPoolId": "us-east-1_ijO4ZP18a",
            "userPoolClientId": "46d36vafu81thuis9iipuaesos",
            "identityPoolId": "us-east-1:e1c890bd-94dd-45f7-b577-eb9991eee396"
        }
    },
    "API": {
        "REST": {
            "code": {
                "endpoint": "https://zoyvhuqeoi.execute-api.us-east-1.amazonaws.com/PROD/",
                "region": "us-east-1"
            },
            "codev2": {
                "endpoint": "https://5661f6d0vj.execute-api.us-east-1.amazonaws.com/PROD/",
                "region": "us-east-1"
            },
            "register": {
                "endpoint": "https://avuvhm4jtg.execute-api.us-east-1.amazonaws.com/PROD/",
                "region": "us-east-1"
            }
        }
    },
    "Websocket": "wss://5a2jrhf434.execute-api.us-east-1.amazonaws.com/PROD"
};
export default aws_exports;